import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"

const NotFoundPage = () => (
  <Layout>
    <SEO metaTitle="404 Not Found" />
    <Header />
    <div className="container container--wide island--dubs text-center">
      <h1>404 NOT FOUND</h1>
      <p>The page you were looking for doesn't exist.</p>
      <p><Link to="/">Return to Homepage</Link></p>
    </div>
  </Layout>
)

export default NotFoundPage
